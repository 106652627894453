.normalPrice {
  display: block;
  text-decoration-line: line-through;
  line-height: 1;
}

@media screen and (max-width: 1036px) {
  .normalPrice {
    display: inline-block;
  }
}

.subscriptionPrice {
  display: inline-block;
  @media screen and (max-width: 1036px) {
    margin-left: 4px;
  }
}

.meal {
  margin: 0 4px;
}
