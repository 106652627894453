.cartItemRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px dashed #e8e9eb;
  padding-top: 20px;
  padding-bottom: 20px;
  &:last-child {
    border-bottom-style: solid;
  }
}

@media screen and (max-width: 1036px) {
  .cartItemRow {
    display: block;
    border-bottom: 1px dashed #e8e9eb;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.detailColumn {
  width: 260px;
  display: flex;
  align-items: center;
}

.imageContainer {
  width: 90px;
}

.image {
  width: 100%;
  max-width: 80px;
  height: auto;
}

.title {
  width: calc(100% - 90px);
}

.priceColumn {
  width: calc(100% - 260px);
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1036px) {
  .priceColumn {
    width: 100%;
  }
}

.selection {
  width: 75%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 1036px) {
  .selection {
    justify-content: flex-start;
  }
}

.quantity {
  width: 80px !important;
  height: 44px !important;
  margin-left: 20px !important;
  margin-right: 5px !important;
}

@media screen and (max-width: 1036px) {
  .quantity {
    margin-left: 0 !important;
  }
}

.totalPrice {
  width: 30%;
  max-width: 120px;
  text-align: right;
}
