.container {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background-color: #ff6478;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}

// PCだとアイコンが大きくなるので少しずらす
.pcBadge {
  top: -6px;
  right: -11px;
}

.iconAlign {
  vertical-align: middle;
}
