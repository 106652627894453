.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-wrapper {
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.swiper-pagination {
  bottom: 0 !important ;
}
.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #ff9b00;
}
